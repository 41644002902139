import React from 'react'
import tw from 'twin.macro'
import { navigate } from 'gatsby'
import { useAtom } from 'jotai'

// components
import Cart from '../components/DatHangPage/Cart'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import Layout from '../components/Layouts/cakeDefault'

import { orderItemsAtom } from '../states/selectedProduct'
import {
  getTotalOrderValue,
  getTotalDiscount,
  currencyFormatter,
} from '../utils/utils'

const CartSection = () => {
  const handleOnClick = () => {
    navigate('/checkout/')
  }

  return (
    <TailwindLayout>
      <Layout endPoint={'cart'} title="Savor Cake | Cart - Giỏ hàng">
        <Cart>
          <TotalPrice handleConfirmBtn={handleOnClick} />
        </Cart>
      </Layout>
    </TailwindLayout>
  )
}

export default CartSection

const ConfirmButton = tw.div`w-full px-10 sm:px-20 py-5
bg-savor-cake-primary-green-avocado-0 text-white 
  font-bold text-base leading-tight
  uppercase rounded
  hover:shadow-lg hover:-translate-y-1 
  focus:shadow-lg focus:outline-none focus:ring-0
  disabled:bg-gray-300 disabled:text-gray-500
  active:bg-blue-800 active:shadow-lg 
  transition duration-300 ease-in-out cursor-pointer`

const TotalPrice = ({ handleConfirmBtn }) => {
  const [orderItems] = useAtom(orderItemsAtom)
  return (
    <div tw="flex flex-row justify-center items-center space-x-2 sm:space-x-24 gap-4 md:gap-5">
      <div tw="flex flex-col md:flex-row gap-1 md:gap-5">
        <div>
          <p tw="text-base md:text-lg mb-1">Tổng tạm tính:</p>
          <p tw="text-lg font-bold md:text-xl">
            {currencyFormatter.format(getTotalOrderValue({ orderItems }))}
          </p>
        </div>
        <div>
          <p tw="text-base md:text-lg mb-1">Đã giảm giá:</p>
          <p tw="text-lg font-bold md:text-xl">
            {currencyFormatter.format(getTotalDiscount({ orderItems }))}
          </p>
        </div>
      </div>
      <div tw="m-0!">
        <ConfirmButton onClick={handleConfirmBtn}>Đặt hàng</ConfirmButton>
      </div>
    </div>
  )
}
